.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.remove-Button {
    background: #ff9789;
    height: 35px;
    width: 100px;
    font-size: medium;
    border: None;
    border-radius: 3px;
}

.button {
    color: #282c34;
    border: None;
    padding: 5px;
    border-radius: 3px;
    font-size: medium;
}

#sort{
    border:None
}

#sortActive{
    border:None;
    background-color:#4c6faf;
}

.button-disabled {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: large;
    background: #cccccc;
    color: #282c34;
}

.nextButton {
    background: #89f1ff;
    color: #282c34;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    /* font-size: 1em; */
    font-size: large;
}

.createButton {
    background: #89f1ff;
    height: 35px;
    width: 100px;
    font-size: large;
}

.modifyButton {
    background: #89f1ff;
    height: 35px;
    width: 100px;
    font-size: medium;
    border: None;
}

.wholeApp {
    background: white;
    position: relative;
    min-height: 100vh;
    top: 0;
    right:0;
    bottom:0;
    left: 0;
    padding-bottom: 10%;
}

.header {
    background: palevioletred;
}

.wrapper {
    top:0;
    right:0;
    bottom:0;
    left:0;
    position:absolute;
}

.contentpage {
    height:auto;
}

.smallChart {
    width: 260px;
    height: 420px;
    display: inline-block;
    margin-bottom: 35px;
    margin-left: 30px;
    background: #ECECEC;
    font-size: x-large;
}

.smallChartCategorical {
    width: 260px;
    height: 360px;
    display: inline-block;
    margin-bottom: 35px;
    margin-left: 30px;
    background: #ECECEC;
    font-size: x-large;
}

.smallHeader {
    padding-left: 50px;
    padding-bottom: 30px;
}

.largeHeader {
    padding-left: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 0;
    background-color: #0028A5;
    color: #fff9ef;
    position: sticky;
    top: 0px;
    z-index: 1
    /* Remove the 3 properties before this comment to make it unsticky */
}

#weightingRadio {
    padding-left: 5px;
}

.footer {
    background-color: #0028A5;
    color: #fff9ef;
}

.text {
    padding-left: 50px;
    font-size: x-large;
}

.smallText {
    padding-left: 25px;
}


.numericalAsf {
    padding-top: 10px;
    padding-left: 2%;
    width: 90%;
}

.vertical-align {
    position: absolute;
    top: 700px;
    overflow: visible;
}

.vertical-align-scoreAssignment {
    position: absolute;
    top: 700px;
    left: 64.4%;
    overflow: visible;
}

.vertical-align-categorical {
    position: absolute;
    top: 700px;
    left: 75%;
    overflow: visible;
}

.button-align {
    position: absolute;
    top: 152%;
    left: 69%;
    overflow: visible;
}

.button-align-multipoint {
    position: absolute;
    top: 160%;
    left: 69%;
    overflow: visible;
}

.select-align {
    position: absolute;
    top: 154%;
    left: 69%;
    overflow: visible;
}

.button-home {
    width: 250px;
    height: 70px;
    background: #89f1ff;
    font-size: x-large;
}

.scoreAssignment {
    float: right;
    width: 50px;
}

th thead {
position: sticky;
top: 0;
}

table {
    text-align: center;
    width: 100%;
    border-collapse: collapse; 
  }

.pagination a {
color: black;
float: left;
padding: 8px 16px;
border: 1px solid #ddd;
text-decoration: none;
/* Makes text unselectable : stackoverflow */
-webkit-touch-callout : none;     /* iOS Safari */
-webkit-user-select   : none;     /* Chrome/Safari/Opera */
-moz-user-select      : none;     /* Firefox */
-ms-user-select       : none;     /* Internet Explorer/Edge */
}

.pagination a:hover{background-color: #ddd;}

.pagination li.active {
background-color: #4c6faf;
color: white;
}
   
.activePage {
background-color: #4c6faf;
color: white;
}

.activePage a:hover{
background-color: #4c6faf;
color: white;
}

